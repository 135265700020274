<template>
  <div class="container">
    <img class="bg" :src="require('../assets/trill-fail-bg.png')" alt="">
    <div class="tips flex-column">
      <div class="tip-1">授权失败</div>
      <div class="tip-2">请确保抖音号处于公开状态后，重试</div>
    </div>
  </div>
</template>
<script>


export default {
  name: "TrillFail",
  created() {
    document.title = '结果页'
  },
}
</script>

<style scoped>
.container {
  background: #fff;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 22.67vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.bg {
  width: 138.13vw;
  height: 75.47vw;

}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tips {
  margin-top: -9.6vw;
}

.tip-1 {
  font-size: 20px;
  color: #666;
}

.tip-2 {
  font-size: 14px;
  color: #666;
  margin-top: 0.53vw;
}
</style>
